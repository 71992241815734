
.main-board-card {
    border-radius: 12px;
    padding: 18px;
    min-height: 190px;
    display: flex;
    align-items: center;

    &__icon {
        margin-right: 8px;
    }
}
@media (min-width: 768px) {
    .main-board-card {
        border-radius: 16px;
        padding: 24px 40px;
    }
}
