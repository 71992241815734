
.map-image {
    position: relative;
    aspect-ratio: 792/898;
    img {
        display: block;
    }
    .v-btn {
        transform: translate(-25%, -25%);
        position: absolute;
        min-width: initial !important;
        width: calc(120 / 792 * 100%) !important;
        height: calc(100 / 898 * 100%) !important;
        opacity: 0;
        a {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
