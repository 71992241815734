
.visual {
    position: relative;
    height: 100%;
    min-height: 348px;
    max-width: 100%;
    aspect-ratio: 1920 / 980;
    &__image {
        aspect-ratio: 1920 / 980;
        min-width: 680px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }
    &__divider {
        border-width: 1px;
        border-color: #fff;
    }
    .swiper-container {
        height: inherit;
        .swiper-wrapper {
            height: inherit;
            .swiper-slide {
                height: inherit;
                display: flex;
                align-items: end;
                padding-bottom: 5%;
            }
        }
    }
    .swiper-pagination {
        position: static;
        justify-content: start;

        &-bullets {
            margin-top: 24px;
            margin-left: -6px;
            ::v-deep {
                .swiper-pagination-bullet {
                    margin: 0 6px;
                    background-color: rgba(255, 255, 255, 0.4);
                    opacity: 1;
                    width: 8px;
                    height: 8px;
                    &-active {
                        width: 20px;
                        height: 20px;
                        border: 1px solid #fff;
                        background-color: transparent;
                        position: relative;
                        &::after {
                            content: "";
                            width: 8px;
                            height: 8px;
                            border-radius: inherit;
                            background-color: #fff;
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1440px) {
    .visual {
        aspect-ratio: initial;
    }
}
