
.icon-link-arrow {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 22px;
    .v-icon {
        position: absolute;
        top: 0;
        opacity: 0;
        animation: ToRight 2s infinite;
        &:first-child {
            left: 0;
            animation-delay: 0.2s;
        }
        &:last-child {
            right: 0;
        }
    }
}

@keyframes ToRight {
    0% {
        transform: translateX(0%);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateX(8px);
        opacity: 0;
    }
}
