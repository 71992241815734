
.main-map {
    &__image {
        margin: 58px auto 0;
    }
    &__story-book {
        margin: 0 auto 0;
        border-radius: 12px;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
    }
}
@media (min-width: 1440px) {
    .main-map {
        padding-top: 58px;
        &__story-book {
            border-radius: 16px;
        }
    }
}
