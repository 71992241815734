
.main-board-notice {
    border-radius: 12px;
    box-shadow: 0 0 12px 0 rgba(9, 20, 48, 0.1) !important;
    padding: 18px;
    min-height: 402px;

    &__icon {
        margin-right: 4px;
    }

    &__table {
        thead {
            > tr {
                > th {
                    &:last-child {
                        width: 20%;
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .main-board-notice {
        border-radius: 16px;
        padding: 24px 40px;
    }
}
