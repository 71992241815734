
.header-menu-lists {
    .header-menu-list {
        &:hover {
            .link {
                color: var(--v-primary-base);
            }
        }
    }
}
