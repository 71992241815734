
::v-deep {
    .v-main__wrap {
        padding-top: 0;
    }
}
.fp-section.section {
    padding: 0;
    overflow: hidden;
    &--map {
        background-image: url(/images/main/map/main-map-background.png);
        background-size: cover;
    }
    &--people {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 100vw;
            height: 50%;
            background-color: var(--v-primary-base);
            z-index: -1;
        }
    }
}
@media (max-width: 1463px) {
    .fp-section.section {
        padding: 60px 0;
        height: auto !important;
        ::v-deep {
            .fp-tableCell {
                height: auto !important;
            }
        }
        &--visual {
            padding-top: 0;
            margin-top: 50px;
        }
        &--footer {
            display: none;
        }
    }
}
