
.header {
    background-color: #fff;
    transition: all ease-out 0.3s;
    .header-primary-container {
        background-color: inherit;
        .header-primary {
            background-color: inherit;
            border-bottom: 1px solid;
            border-color: var(--v-grey-lighten4);
        }
    }
}
.pc-menu {
    display: none;
}
@media (min-width: 1464px) {
    .pc-menu {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    // fullpage Main
    .fp-viewing-0 .header {
        background-color: transparent;
        .header-primary-container {
            background-color: inherit;
            .header-primary {
                background-color: inherit;
                border-bottom: 1px solid;
                border-color: rgba(255, 255, 255, 0.2);
            }
        }
        ::v-deep {
            .header-menu-lists {
                .header-menu-list {
                    .link {
                        color: #fff;
                    }
                }
            }
            .header-customer-lists {
                .v-btn {
                    color: #fff;
                }
            }
        }
        .header-logo {
            > h1 {
                > a {
                    background-image: url(/images/ci-w.svg);
                }
            }
        }

        &:hover {
            background-color: #fff;
            .header-logo {
                > h1 {
                    > a {
                        background-image: url(/images/ci.svg);
                    }
                }
            }

            ::v-deep {
                .header-menu-lists {
                    .header-menu-list {
                        &:hover {
                            .link {
                                color: var(--v-primary-base);
                            }
                        }
                        .link {
                            color: var(--v-grey-darken4);
                        }
                    }
                }
                .header-customer-lists {
                    .v-btn {
                        color: var(--v-grey-darken4);
                    }
                }
            }
        }
    }
}
