
@import "@/styles/_variables.scss";

.footer {
    padding: 40px 0;
}
@media (min-width: 768px) {
    .footer {
        padding: 60px 0;
    }
}
