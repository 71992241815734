
.archive-card {
    max-width: 342px;
    margin: 0 auto;
    transition: all 0.3s;
    &__inner {
        border-radius: 12px !important;
        overflow: hidden;
        transition: all 0.3s;
    }
    &__image {
        border-radius: inherit;
        transition: all 0.3s;
    }
}
@media (min-width: 768px) {
    .archive-card {
        padding-top: 50px;
        > &__inner {
            border-radius: 16px !important;
        }
        &:hover {
            .archive-card {
                &__inner {
                    box-shadow: 10px 10px 32px 0 rgba(9, 20, 48, 0.1);
                    transform: translateY(-50px);
                }
                &__image {
                    transform: scale(1.2);
                }
            }
        }
    }
}
