
.swiper-container {
    overflow: visible;
    .swiper-wrapper {
        overflow: visible;
        // transition-timing-function: linear;
    }
}
.main-people-card {
    border-radius: 12px;
    aspect-ratio: 586 / 392;
    max-width: 586px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    box-shadow: none !important;
    &__image,
    &__title {
        transition: all 0.3s;
    }
    &__image {
        position: absolute;
        display: block;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    &__title {
        width: 100%;
        height: 101%;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
    }
}
@media (min-width: 768px) {
    .main-people-card {
        border-radius: 16px;
    }
}
@media (min-width: 1440px) {
    .main-people {
        padding-top: 130px;
    }
    .main-people-card {
        &__title {
            opacity: 0;
        }
        &:hover {
            box-shadow: 10px 10px 32px 0 rgba(9, 20, 48, 0.1) !important;
            .main-people-card {
                &__image {
                    transform: translate(-50%,-50%) scale(1.1);
                }
                &__title {
                    opacity: 1;
                }
            }
        }
    }
}
